@import url('https://fonts.googleapis.com/css?family=Roboto');
.nav-tab {
    font-family: 'Roboto', sans-serif;
    /* background-color: #fff; */
    display: inline-block;
    padding: 10px 25px;
    color: #bbb;
    /* border-bottom: 1px solid #ddd; */
    text-decoration: none;
}

.nav-tab:hover {
    background-color: oldlace;
}

.nav-tab.active {
    background-color: #fff;
    color: #555;
    border: 1px solid #fff;
    /* border-top: 2px solid orange; */
    border-top: 2px solid grey;
    border-bottom: 1px solid #fff;
    cursor: default;
}

.tabs {
    list-style-type: none;
    /* height: 26px;
    margin: 0px;
    padding: 0px;
    padding-left: 4px;
    width: 50000px; */
    /* border-style: solid; */
    /* border-width: 0 0 1px 0; */
  }