.header {
    background: #a80000;
    color: #fff;
    border-radius: 20px;
    padding: 25px 25px 60px;
    position: relative;
}

.progress-student {
    margin: auto;
    margin-top: 90px;
    margin-bottom: 50px;
    height: 120px;
}

/* Progress */
.stepper-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
  height: 120px;
  
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 60px;
  flex: 1;
}
.step-name {
  text-align: center;
}

/* .stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 3px solid #ccc;
  width: 200px;
  top: 20px;
  left: -50%;
  z-index: 2;
} */

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 1px solid #fff;
  width: 120px;
  top: 15px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item.completed .step-counter {
  background-color: rgb(255, 0, 0);
}

.stepper-item.completed-only .step-counter {
  background-color: rgb(255, 0, 0);
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 3px solid rgb(255, 0, 0);
  width: 120px;
  top: 15px;
  left: 50%;
  z-index: 3;
}
/* .stepper-item.completed::before {
  position: absolute;
  content: "";
  border-bottom: 3px solid #4bb543;
  width: 100px;
  top: 20px;
  left: -50%;
  z-index: 3;
} */

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}

/* ------- */

/* profile */
.profile {
  background: #fff;
  width: 95%;
  margin: auto;
  /* height: 130px; */
  position: relative;
  border-radius: 20px;
  padding: 20px;
  top: -70px;
  box-shadow: inset;
}
.profile-1{
  display: flex;
  align-items: center;
}
.btn-nav-menu{
  display: flex;
}
.btn-nav-menu a{
  text-decoration: none;
  /* color:#D9DCE3; */
  color: #000;
}

.btn-menu{
  width: 500px;
  padding: 5px;
  margin-right: 10px;
  margin-bottom: 6px;
  background: #d2d2d2;
  border-radius: 5px;
  border: none;
  text-align: center;
}
.btn-menu i{
  margin-right: 5px;
}
/* ------ */


/* SCROLL */
.scrool::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

.scrool::-webkit-scrollbar-track {
  background: rgb(179, 177, 177);
  border-radius: 5px;
}

.scrool::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136);
  border-radius: 5px;
}

.scrool::-webkit-scrollbar-thumb:hover {
  background: rgb(100, 100, 100);
  border-radius: 5px;
}

.scrool::-webkit-scrollbar-thumb:active {
  background: rgb(68, 68, 68);
  border-radius: 5px;
}
/* ----- */

section{
  width: 95%;
  margin: auto;
  position: relative;
  top: -20px;
}

.card-progress-log {
  height: 400px;
}

.card .bg-red-color{
  background: #a80000;
  color: #fff;
}
.bg-red{
  background-color:#a80000 ;
}
.color-red{
  color: #a80000;
}


@media (width < 769px){
  .header{
    border-radius: 0;
    padding-bottom: 120px;
  }
  .profile{
    /* height: 290px; */
    top: -120px;
    padding: 25px 10px;
  }
  .profile-1 {
    display: flex;
    flex-direction: column;
  }
  .img-profile, .profile-name {
    text-align: center;
  }
  section {
    top: -80px;
  }
  .btn-nav-menu{
    display: flex;
    flex-wrap: wrap;
    margin: 10px auto 0;
    /* background: #000; */
  }

  .btn-menu {
    padding: 13px 0;
    width: 70%;
    margin: 0 auto 10px;
  }
  .content{
    top: -70px;
  }
}

@media (width > 767px){
  .profile-1{
    justify-content: center;
  }
  .btn-nav-menu{
    /* flex-direction: row; */
    justify-content: center;
    flex-wrap: wrap;
    /* width: 500px; */
  }
  .btn-menu {
    width: 150px;
    margin: 0 auto 10px;
  }
  .card-student {
    /* height: 100%; */
    margin-bottom: 20px;
  }
}

@media (width > 1023px){
  .profile-1{
    justify-content: flex-start;
  }
  .nav-menu{
    align-items: center;
    background: #000;
    position: relative;
  }
  .btn-nav-menu{
    flex-wrap: wrap;
    justify-content: center;
  }
  .btn-menu{
    width: 140px;
    padding: 5px;
    height: 50px;
  }
  .card-progress {
    height: 1199px;
  }
  .card-progress-log{
    height: 850px;
  }
}

@media (width > 1439px){
  .btn-menu{
    width: 130px;
    padding: 5px 4px;
    margin: 5px;
  }
}

@media (width > 2559px){
  .btn-menu{
    width: 130px;
    padding: 5px 4px;
    margin: 0 5px;
  }
}